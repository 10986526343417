import * as React from 'react';

import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";

const myTheme = extendTheme({
    colors: {
        background: "#c4c3d0",
        text: "#1A202C"
    },
    fonts: {
        body: 'Zilla Slab, serif'
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
    },
    fontWeights: {
        light: 300,
        normal: 400,
        medium: 500
    },

});


const Layout = ({ children }) => {
    return (
        <ChakraProvider theme={myTheme}>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300;400;500&display=swap" rel="stylesheet" />

            <Box bg="background" h='100%' p='5%' minHeight='100vh'>

                {children}
            </Box>


        </ChakraProvider >

    )
};



export default Layout;