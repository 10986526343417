import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import * as React from "react";

import Layout from '../components/layout';
import CarlyPicture from '../images/carly.png';


const IndexPage = () => {
  return (
    <Layout>
      <title>Carly Ilg</title>
      <Flex flexDirection={['column', 'column', 'row']}>

        <Box w={['100%', '100%', '25%']} display='inline-block' textAlign='center' mb='20px'>
          <Text fontSize='md' pb='15px'>
            Carly Ilg
          </Text>

          <Image
            alt="Picture of Carly Ilg"
            src={CarlyPicture}
            maxHeight='100px'
            maxWidth='100px'
            borderRadius='50px'
            border='2px dashed white'
            p='5px'
            m='auto'
          />
        </Box>
        <Box w={['100%', '100%', '75%']} textAlign={['center', 'center', 'left']}>
          <Text fontSize='md' pb='15px' fontStyle='body'>
            About me
          </Text>
          <Text fontSize='md' pb='15px'>
            Boston-area JavaScript developer with a background in physics. Craft lady, cat lady, and in a past life, frisbee coach.
          </Text>
          <Box flexDirection='row'>
            <Link fontSize='xs' pb='15px' href='https://www.linkedin.com/in/carly-ilg/' isExternal textDecoration='underline' mr='10px'>
              LinkedIn
          </Link>
            <Link fontSize='xs' pb='15px' href='https://github.com/cilg017' isExternal textDecoration='underline'>
              GitHub
          </Link>
          </Box>
        </Box>
      </Flex>
    </Layout>
  )
}

export default IndexPage
